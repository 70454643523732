<script setup lang="ts">
import NewsletterSignup from '@playa/components/newsletter-signup/NewsletterSignup.vue'
import PlayaModal from '@playa/components/PlayaModal.vue'
import { ref } from 'vue'

defineProps<{
  footerTermsMenu: any
}>()

const showJoin = ref(false)
</script>

<template>
  <div>
    <div class="bg-midnight border-t-[5px] border-ochre py-16 md:py-10">
      <div class="container mx-auto grid md:grid-cols-3 gap-10 md:gap-24">
        <div class="flex flex-col justify-center space-y-4">
          <div class="text-ochre text-lg font-bold uppercase">
            Connect with us
          </div>
          <div class="font-sandpiper-serif text-white font-thin text-sm leading-6">
            4500 SE Pine Valley St, Port St. Lucie, FL 34952<br>
            Hotel: <a class="underline" href="tel: 7723985100">(772) 398-5100</a>
          </div>
          <ul class="flex items-center space-2 text-ochre">
            <li>
              <a aria-label="Facebook Feed" href="https://www.facebook.com/sandpiperbayresort" target="_blank" rel="nofollow">
                <Icon class="w-6 h-6" name="ri:facebook-fill" />
              </a>
            </li>
            <li>
              <a aria-label="Instagram Feed" href="https://www.instagram.com/sandpiper_bayresort/" target="_blank" rel="nofollow">
                <Icon class="w-6 h-6" name="ri:instagram-line" />
              </a>
            </li>
          </ul>
        </div>
        <div class="flex justify-center items-center row-start-1 md:row-auto">
          <img src="/imgs/sandpiper/logo-large.png" class="max-w-[300px] sm:max-w-[200px] lg:max-w-[300px]">
        </div>
        <div class="flex flex-col justify-center space-y-4">
          <div class="text-ochre text-lg font-bold font-sandpiper-sanserif uppercase">
            Exclusive Email Sign-up
          </div>
          <div class="font-sandpiper-serif text-white font-thin text-sm leading-6">
            Be the first to know about special offers and
            happenings
          </div>
          <div class="flex items-center space-2 text-ochre">
            <button
              class="bg-ochre text-white uppercase font-medium px-4 py-3 text-sm"
              @click="showJoin = true"
            >
              Join Today
            </button>
            <PlayaModal :show="showJoin" :can-close="true" @close="showJoin = false">
              <div id="sandpiper-newsletter-signup" class="bg-white px-12 py-12">
                <NewsletterSignup form-id="0ADBD011-880A-4317-B507-713441AFC57A" />
              </div>
            </PlayaModal>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white">
      <div
        class="flex flex-col md:flex-row justify-center md:justify-between p-4 text-xs"
      >
        <div>
          <ul
            v-if="footerTermsMenu"
            class="flex flex-col md:flex-row items-center list-reset"
          >
            <li v-for="menuItem in footerTermsMenu?.data?.menu_items" :key="menuItem.id" class="my-2 md:my-0 mx-2">
              <a class="text-xs text-grey-darker" :href="menuItem.permalink">
                {{ menuItem.name }}
              </a>
            </li>
          </ul>
        </div>
        <div class="text-center md:text-right mt-8 md:mt-0">
          &copy; {{ new Date().getFullYear() }} Playa Management USA, LLC All
          rights reserved
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#sandpiper-newsletter-signup {
  .x-container {
    @apply px-4;
  }

  h2 {
    @apply text-2xl font-extralight md:text-4xl md:font-thin text-center text-gray-600 tracking-widest;
  }
  h4 {
    @apply text-xl font-extralight md:text-2xl text-center text-gray-500 mt-1 tracking-wide;
  }

  form {
    .texts {
      @apply flex justify-center flex-wrap gap-3 gap-y-6 mt-16;
    }

    .input-group {
      @apply font-extralight text-gray-600 md:text-base;

      input {
        @apply relative outline-0 border-0 border-b border-gray-500 w-[240px] ml-1 -top-1;
      }

      select {
        @apply border-0 border-b border-gray-500 ml-2 outline-0 pb-1;
      }
    }
  }

  .checkboxes-wrapper {
    @apply text-xs text-gray-600 mt-12;

    .checkbox {
      @apply flex items-start mb-4;

      input[type='checkbox'] {
        @apply transform scale-[1.4];
      }

      label {
        @apply ml-3;
      }
    }
  }
}
</style>
