<script setup lang="ts">
import { usePageField } from '@voix/composables/usePageField'
import { useBookingWidgetStore } from '@playa/store/bookingWidgetStore'
import { v4 as uuidv4 } from 'uuid'
import { usePageStore } from '@voix/store/pageStore'

defineProps<{
  menu: any
}>()
const pageField = usePageField()
const hideBookNow = pageField.get('hideBookNow', false)
const hideNavigation = pageField.get('hideNavigation', false)

const pageStore = usePageStore()

const showMobileMenu = ref(false)
const bookingWidgetStore = useBookingWidgetStore()

const showBookingModal = computed(() => {
  return bookingWidgetStore.showBookingModal
})
const instanceId = uuidv4()
const { toggleBookingModal } = bookingWidgetStore
</script>

<template>
  <div v-if="!hideNavigation.value" class="relative  pt-24 ">
    <div class="hidden md:block fixed z-[9999] top-0 left-0 right-0 bg-white">
      <div class="flex  items-center justify-between  container mx-auto py-4">
        <div>
          <img src="/imgs/sandpiper/logo-small.png">
        </div>
        <div class="hidden lg:flex items-center">
          <ul v-if="pageStore.currentPage?.path === '/'" class="flex font-bold uppercase lg:space-x-8 xl:space-x-12 2xl:space-x-16">
            <li v-for="menuItem in menu?.menu_items" :key="menuItem.id">
              <a :href="menuItem.permalink">{{ menuItem.name }}</a>
            </li>
          </ul>
          <div class="ml-8">
            <button v-if="!hideBookNow.value" class="bg-ochre text-white uppercase font-medium px-4 py-3 text-sm" @click="toggleBookingModal(instanceId)">
              Book Now
            </button>
          </div>
        </div>
      </div>
      <div v-if="showBookingModal" class="relative px-8 z-0 flex justify-center inline-flight-booking bg-black">
        <FlightBookingForm />
      </div>
    </div>

    <div class="lg:hidden">
      <div class="fixed z-[9999] top-0 left-0 right-0 ">
        <div class="bg-white flex items-center justify-between p-4 px-6">
          <img src="/imgs/sandpiper/logo-small.png">
          <div class="flex items-center space-x-3">
            <button v-if="!hideBookNow.value" class="bg-ochre text-white uppercase font-medium px-4 py-3 text-sm" @click="toggleBookingModal(instanceId)">
              Book Now
            </button>
            <button @click="showMobileMenu = !showMobileMenu">
              <Icon class="text-ochre w-8 h-8" name="heroicons:bars-3-solid" />
            </button>
          </div>
        </div>

        <div v-if="showBookingModal" class="fixed inset-0 lg:relative px-8 z-0 flex justify-center inline-flight-booking bg-black">
          <div class="relative z-10 w-full">
            <FlightBookingForm class="mt-12 lg:mt-0" />
          </div>
          <button class="p-4 absolute z-20 top-0 right-0 m-4 pointer-events-auto" @click="toggleBookingModal(instanceId)">
            <Icon name="heroicons:x-mark-20-solid" class="text-white w-12 h-12" />
          </button>
        </div>
      </div>

      <div
        class="fixed z-[9999] inset-0 bg-white flex flex-col space-y-16 lg:space-y-0 p-12 duration-300 ease-out"
        :class="{ '-translate-x-full': !showMobileMenu }"
      >
        <div class="flex items-center justify-between">
          <div><img src="/imgs/sandpiper/logo-small.png"></div>
          <button @click="showMobileMenu = !showMobileMenu">
            <Icon name="heroicons:x-mark-20-solid" class="text-ochre w-12 h-12" />
          </button>
        </div>

        <ul class="font-bold uppercase space-y-4 text-xl">
          <li v-for="menuItem in menu?.menu_items" :key="menuItem.id">
            <a :href="menuItem.permalink" @click="showMobileMenu = false">{{ menuItem.name }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
